import * as ACTION from "../constants/billingActionTypes";

export const fetchBilling = (payload, callback) => ({
    type: ACTION.FETCH_BILLINGS,
    payload: {payload, callback}
});

export const fetchDetails = payload => ({
    type: ACTION.FETCH_DETAILS,
    payload: payload
});