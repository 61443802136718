import { Fragment, useEffect, useState ,useRef} from "react";
import { connect } from "react-redux";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { invoiceList } from "../../../api/billingApi";
import { useIsMounted } from "../../../hooks/IsMounted";
import InvoiceDetails from "./InvoiceDetails";
import Utils from "../../../helpers/Utils";
import moment from "moment-timezone";
import DynamicSkeleton from "./DynamicSkeleton";

const InvoiceHistory = (props) => {
    const isMounted = useIsMounted();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    // const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(1);
    // const [perPage, setPerPage] = useState(10);
    //scrolling
    const [hasMore, setHasMore] = useState(true);
    const [scrolling, setScrolling] = useState(false);

    const scrollingDiv = useRef();
    const sortType = 'desc';
    const sortBy = 'created_at';
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [dataForModal, setDataForModal] = useState(null);
    const perPage = 20;

    const PACKAGE_RENEW = 1;
    //const PACKAGE_BUY = 2;
    const AUTO_RECHARGE = 3;
    const ADDITIONAL_USER_BUY = 4;
    const INSTANT_TOP_UP = 112;

    // useEffect(() => {
    //     loadData();
    // }, [currentPage, reset, perPage, sortType, debouncedQuery]);

    useEffect(() => {
        loadData(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPage,sortType, debouncedQuery]);

    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    // const loadData = () => {
    //     setLoading(true);

    //     invoiceList({
    //         "form_data": {
    //             page: currentPage + 1,
    //             per_page: perPage,
    //             sort_by: sortBy,
    //             sort_type: sortType,
    //             query: debouncedQuery
    //         }
    //     })
    //     .then(response => {
    //         setData(response.data.data.data);
    //         setTotal(response.data.data.total);
    //     }).finally(() => {
    //         setLoading(false);
    //     });
    // }


    const loadData = (nextPage,_clearPrev = false) => {
        setLoading(true);
        invoiceList({
             "form_data": {
                 // page: currentPage + 1,
                 page: nextPage,
                 per_page: perPage,
                 
                 sort_by: sortBy,
                 sort_type: sortType,
                 query: debouncedQuery
             }
         })
         .then(response => {
 
             let tempData = _clearPrev ? [] : [...data];
 
             response.data.data.data.forEach(element => {
                 tempData.push(element);
             });
 
             setData(tempData);
             setPage(nextPage);
 
             if (response.data.data.next_page_url) {
                 setHasMore(true);
             } else {
                 setHasMore(false);
             }
 
         }).finally(() => {
             setLoading(false);
             setScrolling(false);
         });
     }

    const renderData = () => {
        if (loading) {
            return (
                <tr>
                    <td colspan="7">
                        <div>
                           <DynamicSkeleton count={6}/>
                        </div>
                    </td>
                </tr>

            )
        } else if (!loading && !data.length) {
            return (
                <tr>
                    <td colspan="7">
                        <div style={{paddingTop: 50}}>
                            NO DATA AVAILABLE
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {
            return data.map((item,index) => {

                let usageDate = '';

                if(item.created_at !== undefined){
                    let userTimeZone = Utils.getAccountData('userTimezoneRaw');

                    usageDate = moment.utc(item.created_at).tz(userTimeZone).format('MM-DD-YY h:mm:ss');


                }

                let invoiceFor = 'Package Buy';

                if(item.invoice_for === PACKAGE_RENEW){
                    invoiceFor = 'Package renew';
                }else if(item.invoice_for === AUTO_RECHARGE){
                    invoiceFor = 'Auto Recharge';
                }else if(item.invoice_for === ADDITIONAL_USER_BUY){
                    invoiceFor = 'Additional User Buy';
                }else if(item.invoice_for === INSTANT_TOP_UP){
                    invoiceFor = 'Instant Top Up';
                }


                return (
                    <tr>
                        <td class="pl-5 pr-3">
                            <p class="text-dark-blue subtitle-1">{item.invoice_id} </p>
                            <p class="text-gray subtitle-3">{usageDate}</p>
                        </td>
                        <td class="pr-3">
                            <p class="subtitle-1 text-light-blue">{invoiceFor}</p>
                        </td>
                        <td class="pr-3">
                            <p class="subtitle-1 text-dark-blue-65">{item.package_name}
                            </p>
                        </td>
                        {/*<td class="pr-3">*/}
                        {/*    <p class="subtitle-1 text-dark-blue-65">{item.package_type}</p>*/}
                        {/*</td>*/}
                        <td class="pr-3">
                            <p class="subtitle-1 text-dark-blue-65">{item.credit_amount}</p>
                        </td>
                        <td class="pr-3">
                            <p class="subtitle-1 text-dark-blue-65">{item.price}</p>
                        </td>
                        <td class="pr-3">
                            <a href="!#" class="view_invoice_btn accent--bg--color subtitle-1 radius-5 white-text d-flex align-items-center justify-content-center" onClick={e => {
                                e.preventDefault();
                                setDataForModal(item);
                                setModalVisible(true);
                            }}>
                                <svg class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="white"></circle>
                                    <path d="M9.99995 8.39062C9.11205 8.39062 8.39062 9.11205 8.39062 9.99995C8.39062 10.8879 9.11205 11.6093 9.99995 11.6093C10.8879 11.6093 11.6093 10.8879 11.6093 9.99995C11.6093 9.11205 10.8879 8.39062 9.99995 8.39062ZM9.87786 9.5227C9.67809 9.5227 9.5116 9.68918 9.5116 9.88896H8.97886C8.98996 9.38952 9.38952 8.98996 9.87786 8.98996V9.5227Z" fill="#3C7EF3"></path>
                                    <path d="M14.8835 9.66693C14.3396 8.98991 12.3973 6.78125 10 6.78125C7.60266 6.78125 5.66038 8.98991 5.11654 9.66693C4.96115 9.85561 4.96115 10.1331 5.11654 10.3329C5.66038 11.0099 7.60266 13.2185 10 13.2185C12.3973 13.2185 14.3396 11.0099 14.8835 10.3329C15.0388 10.1442 15.0388 9.86671 14.8835 9.66693ZM10 12.3306C8.71254 12.3306 7.66926 11.2874 7.66926 9.9999C7.66926 8.71244 8.71254 7.66915 10 7.66915C11.2875 7.66915 12.3307 8.71244 12.3307 9.9999C12.3307 11.2874 11.2875 12.3306 10 12.3306Z" fill="#3C7EF3"></path>
                                </svg>
                                View Invoice</a>
                        </td>
                    </tr>
                )
            })
        }
    }
    
    // const handleChangePage = (event, newPage) => {
    //     setCurrentPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setPerPage(parseInt(event.target.value, 10));
    //     setCurrentPage(0);
    // };


    const loadMore = (e) => {
        if(hasMore && !scrolling && Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)) {
            setScrolling(true);

            setTimeout(() => {
                loadData(page+1);
            }, 500);
        }
    }


    return (
        <Fragment>
            <div id="invoice__history" class="col s12 bg-gray-light p-0 active scrollable_table__height__calc">
                <div class="white radius-10 usage_history_top d-flex align-items-center justify-content-between px-3 mt-3">
                    <div>
                        {/* <a class="text-dark-blue-65 subtitle-1 p-3" href="!#">All</a>
                        <a class="active text-dark-blue-65 subtitle-1 p-3" href="!#">Monthly</a>
                        <a class="text-dark-blue-65 subtitle-1 p-3" href="!#">Weekly</a>
                        <a class="text-dark-blue-65 subtitle-1 p-3" href="!#">Today</a> */}
                    </div>
                    <div class="usage_search_field">
                        <input type="text" class="radius-5 common__input__field pl-3 m-0" placeholder="Search" onChange={(e) => setQuery(e.target.value)}/>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" fill="#3C7EF3"></path>
                        </svg>
                    </div>
                </div>
                <div class="scrollable_table" onScroll={(e) => loadMore(e)} ref={scrollingDiv}>
                    <table class="invoice_history_table vertical_scroll_table">
                        <thead>
                            <tr>
                                <td class="pl-5">
                                    <p class="text-dark-blue heading-6">Invoice Id</p>
                                </td>
                                <td>
                                    <p class="text-dark-blue heading-6">Invoice for</p>
                                </td>
                                <td>
                                    <p class="text-dark-blue heading-6">Package Name</p>
                                </td>
                                {/*<td>*/}
                                {/*    <p class="text-dark-blue heading-6">Package Type</p>*/}
                                {/*</td>*/}
                                <td>
                                    <p class="text-dark-blue heading-6">Recurring USD</p>
                                </td>
                                <td>
                                    <p class="text-dark-blue heading-6">Price</p>
                                </td>
                                <td>
                                    <p class="text-dark-blue heading-6">Action</p>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {renderData()}
                            {
                                 scrolling && (
                                               <div className="load-more-inbox-thread">Loading more...</div>
                                             )
                             }
                        </tbody>
                    </table>
                    {/* <div class="text-center">
                        <div className="mt-3">
                            <TablePagination
                                component="div"
                                count={total}
                                page={currentPage}
                                onChangePage={handleChangePage}
                                rowsPerPage={perPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </div>
                    </div> */}
                </div>
            </div>
            {
                modalVisible && (
                    <InvoiceDetails
                        hideModal={() => {
                            setModalVisible(false);
                        }}
                        title={'Invoice Details'}
                        data={dataForModal}
                    />
                )
            }
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.billingReducer.user
    }
}
const mapDispatchToProps = dispatch => {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceHistory);