import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as BILLING_API from '../api/billingApi';
import * as ACTION from '../constants/billingActionTypes'
import Utils from "../helpers/Utils";

function* billingWatcher() {
    yield takeEvery(ACTION.FETCH_BILLINGS, fetchCalendarMiddleware);
    yield takeEvery(ACTION.FETCH_DETAILS, fetchDetailsMiddleware);
}

function* fetchCalendarMiddleware(action) {
    try {
        const response = yield call(BILLING_API.fetchbillingList, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_BILLINGS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchDetailsMiddleware(action) {
    try {
        const response = yield call(BILLING_API.fetchDetails, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_DETAILS, payload: responseData.data});

    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* billingMiddleware() {
    yield all([
        billingWatcher(),
    ])
}
