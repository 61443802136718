import { Fragment} from "react"
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ChangeCardStripeForm from "./ChangeCardStripeForm";


const ChangeCard = (props) => {
    const stripePromise = loadStripe(props.stripePublicKey);

    return (
      <Fragment>
        {props.stripePublicKey ? (
          <Fragment>
            <div
              style={
                props.hasCard === false
                  ? { maxWidth: "70%" }
                  : { maxWidth: "700px" }
              }
              className={props.hasCard === false ? "pl-5 pt-5" : ""}
            >
              <Elements stripe={stripePromise}>
                <ChangeCardStripeForm callback={props.callback} />
              </Elements>
            </div>
          </Fragment>
        ) : (
          "Stripe info not set perfectly"
        )}
      </Fragment>
    );
}

export default ChangeCard;