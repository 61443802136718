import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box, Grid } from '@material-ui/core';

const styles = (theme) => ({
  largeSkeleton: {
    height: '40px',  
    marginBottom: '20px', 
    [theme.breakpoints.up('md')]: {
      height: '50px',
    },
  },
  skeletonWrapper: {
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      padding: '40px',
    },
  },
});

const LoadingSkeleton = ({ classes }) => {
  return (
    <Box className={classes.skeletonWrapper} width="100%">
      <Skeleton className={classes.largeSkeleton} variant="rect" width="60%" />
      <Skeleton className={classes.largeSkeleton} variant="rect" width="30%" />

      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Skeleton className={classes.largeSkeleton} variant="rect" width="70%" />
          <Skeleton className={classes.largeSkeleton} variant="rect" width="50%" />
          <Skeleton className={classes.largeSkeleton} variant="rect" width="40%" />
          <Skeleton className={classes.largeSkeleton} variant="rect" width="60%" />
          <Skeleton className={classes.largeSkeleton} variant="rect" width="100%" height={80} />
        </Grid>

        <Grid item xs={4}>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Skeleton className={classes.largeSkeleton} variant="rect" width={150} height={80} />
            <Skeleton className={classes.largeSkeleton} variant="rect" width="100%" height={20} />
          </Box>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Skeleton className={classes.largeSkeleton} variant="rect" width={100} />
        <Skeleton className={classes.largeSkeleton} variant="rect" width="70%" height={60} />
      </Box>
    </Box>
  );
};

export default withStyles(styles)(LoadingSkeleton);
