import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const SkeletonContainer = styled.div`
  padding: 16px;
  overflow: hidden;
`;

const SkeletonElement = styled.div`
  position: relative;
  background-color: #f0f0f0;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, transparent 0%, #fff 50%, transparent 100%);
    animation: ${shimmer} 1.5s ease-in-out infinite;
  }
`;

const SkeletonUserItem = styled(SkeletonElement)`
  height: ${({ height }) => height || '50px'};
  width: ${({ width }) => width || '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '15px'};
  border-radius: 4px;
`;

const DynamicSkeleton = ({ count = 10, width = '100%', height = '50px', marginBottom = '15px', ...otherProps }) => {
  return (
    <SkeletonContainer {...otherProps}>
      {[...Array(count)].map((_, index) => (
        <SkeletonUserItem 
          key={index} 
          width={width} 
          height={height} 
          marginBottom={marginBottom}
        />
      ))}
    </SkeletonContainer>
  );
};

export default DynamicSkeleton;