import CardInfo from "./CardInfo";
import * as Constants from "../Constants";
import InstantTopup from "./InstantTopup";
import AutoRecharge from "../AutoRecharge";
import UsageHistory from "./UsageHistory";
import { useSelector } from "react-redux";
import InvoiceHistory from "./InvoiceHistory";
import { useEffect } from 'react';


const BottomPart = (props) => {

    const isStarter = useSelector(state => state.billingReducer.isStarter);

    useEffect(() => {

        if (props.tab == null) {
            props.setTab(Constants.TAB_CARD_INFO)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div class="row billing-wrapper">
            <div class="col s12">
                <div class="bottom_menu_tabs_container radius-5 white mb-4">
                    <ul class="tabs bottom_menu_tabs d-flex align-items-center pr-3">
                        <li class="tab ml-3">
                            <a
                                href="#card__info"
                                class={`px-3 d-flex justify-content-center align-items-center ${props.tab === Constants.TAB_CARD_INFO ? 'active' : ''}`}
                                onClick={e => {
                                    e.preventDefault();
                                    props.setTab(Constants.TAB_CARD_INFO);
                                    if (!props.isShowTopPart) { props.handleCollapse() }
                                }}
                            >
                                <svg class="mr-3" width="22" height="16" viewBox="0 0 22 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M22 3.56641V2.40625C22 1.07731 20.9227 0 19.5938 0H2.40625C1.07731 0 0 1.07731 0 2.40625V3.56641C0 3.68504 0.096207 3.78125 0.214844 3.78125H21.7852C21.9038 3.78125 22 3.68504 22 3.56641Z" />
                                    <path
                                        d="M0 5.37109V12.7188C0 14.0477 1.07731 15.125 2.40625 15.125H19.5938C20.9227 15.125 22 14.0477 22 12.7188V5.37109C22 5.25246 21.9038 5.15625 21.7852 5.15625H0.214844C0.096207 5.15625 0 5.25246 0 5.37109ZM5.5 11C5.5 11.3797 5.19217 11.6875 4.8125 11.6875H4.125C3.74533 11.6875 3.4375 11.3797 3.4375 11V10.3125C3.4375 9.93283 3.74533 9.625 4.125 9.625H4.8125C5.19217 9.625 5.5 9.93283 5.5 10.3125V11Z" />
                                </svg>
                                Card Info
                            </a>
                        </li>
                        {
                            !isStarter && (
                                <li class="tab">
                                    <a
                                        class={`px-3 d-flex justify-content-center align-items-center ${props.tab === Constants.TAB_INSTANT_POPUP ? 'active' : ''}`}
                                        onClick={e => {
                                            e.preventDefault();
                                            props.setTab(Constants.TAB_INSTANT_POPUP);
                                            if (!props.isShowTopPart) { props.handleCollapse() }
                                        }}
                                        href="#instant__topup"
                                    >
                                        <svg class="mr-3" width="26" height="26" viewBox="0 0 26 26" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13 0C5.8197 0 0 5.82027 0 13C0 20.1797 5.8197 26 13 26C20.1803 26 26 20.1792 26 13C26 5.82084 20.1797 0 13 0ZM13.7869 18.7909V20.2957C13.7869 20.5305 13.6042 20.7013 13.3689 20.7013H12.3658C12.1305 20.7013 11.9409 20.5305 11.9409 20.2957V18.9232C11.0177 18.8814 10.0865 18.6845 9.38157 18.4163C8.99969 18.2715 8.78698 17.8625 8.88825 17.4665L9.05174 16.8295C9.10888 16.606 9.25823 16.4193 9.46247 16.3146C9.6667 16.2083 9.90657 16.1975 10.121 16.2796C10.7925 16.5392 11.5857 16.7282 12.4326 16.7282C13.5109 16.7282 14.2491 16.3118 14.2491 15.5549C14.2491 14.8358 13.6438 14.3815 12.243 13.908C10.2183 13.2269 8.83167 12.281 8.83167 10.4451C8.83167 8.78019 10.0101 7.47448 12.0247 7.07677V5.7043C12.0247 5.46952 12.2255 5.25906 12.4609 5.25906H13.4639C13.6992 5.25906 13.8718 5.46952 13.8718 5.7043V6.94382C14.7526 6.98172 15.3885 7.11241 15.9203 7.28552C16.3226 7.41677 16.5602 7.83824 16.4544 8.24897L16.3107 8.81923C16.2547 9.03647 16.111 9.2209 15.9141 9.32725C15.7172 9.43361 15.4847 9.45285 15.272 9.37987C14.7889 9.21411 14.1586 9.06362 13.3598 9.06362C12.1299 9.06362 11.7322 9.59372 11.7322 10.1238C11.7322 10.7484 12.3947 11.1455 14.003 11.7514C16.2547 12.5463 17.1593 13.5872 17.1593 15.2895C17.1587 16.9737 15.969 18.4129 13.7869 18.7909Z" />
                                        </svg>
                                        Instant Top up
                                    </a>
                                </li>
                            )
                        }
                        {
                            !isStarter && (
                                <li class="tab">
                                    <a class={`px-3 d-flex justify-content-center align-items-center ${props.tab === Constants.TAB_AUTO_RECHARGE ? 'active' : ''}`}
                                        onClick={e => {
                                            e.preventDefault();
                                            props.setTab(Constants.TAB_AUTO_RECHARGE);
                                            if (!props.isShowTopPart) { props.handleCollapse() }
                                        }}
                                        href="#auto__recharge">
                                        <svg class="mr-3" width="27" height="18" viewBox="0 0 27 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.388 13.5729V13.4716C12.8703 13.466 12.3751 13.4378 11.8968 13.3984C11.638 14.0681 11.2722 14.6927 10.8164 15.2498C11.6774 15.3904 12.5496 15.4692 13.4218 15.4805L13.388 13.5729Z" />
                                            <path
                                                d="M11.1089 9.84467C10.6925 7.85266 9.22378 6.23205 7.27116 5.61869C6.73095 5.4555 6.17386 5.37109 5.61115 5.37109C4.21562 5.37672 2.8651 5.89442 1.83534 6.82852C-0.454912 8.91057 -0.6181 12.4613 1.46394 14.7515C3.54599 17.0418 7.09672 17.205 9.38696 15.1229C10.5574 14.0594 11.227 12.557 11.2214 10.9757C11.2214 10.5987 11.182 10.2161 11.1089 9.84467ZM5.61115 10.2217C6.56214 10.2217 7.40058 10.8519 7.65943 11.7692C7.97455 12.9002 7.3218 14.0707 6.18512 14.3914V14.8866C6.18512 15.1961 5.9319 15.4493 5.6224 15.4493C5.31291 15.4493 5.05969 15.1961 5.05969 14.8866V14.397C4.13121 14.1438 3.48972 13.3054 3.48409 12.3431C3.48409 12.0336 3.73731 11.7804 4.0468 11.7804C4.3563 11.7804 4.60952 12.0336 4.60952 12.3431C4.60952 12.8946 5.05969 13.3391 5.61115 13.3391C6.16261 13.3391 6.60715 12.889 6.60715 12.3375C6.60715 11.786 6.15698 11.3415 5.60552 11.3415C4.64328 11.3415 3.80484 10.6944 3.55724 9.76589C3.259 8.64047 3.93426 7.47002 5.06532 7.16615V6.92419C5.06532 6.61469 5.31854 6.36147 5.62803 6.36147C5.93752 6.36147 6.19075 6.61469 6.19075 6.92419V7.17741C7.10797 7.43626 7.73821 8.2747 7.73821 9.22569C7.73821 9.53518 7.48499 9.7884 7.1755 9.7884C6.866 9.7884 6.61278 9.53518 6.61278 9.22569C6.61278 8.67423 6.16261 8.22406 5.61115 8.22406C5.05969 8.22406 4.61515 8.6686 4.60952 9.22569C4.60952 9.77715 5.05969 10.2217 5.61115 10.2217Z" />
                                            <path
                                                d="M13.3684 12.34L13.3346 10.348C12.9858 10.3424 12.6425 10.3311 12.3161 10.3086C12.333 10.5337 12.3443 10.7531 12.3443 10.9726C12.3443 11.4115 12.2992 11.8561 12.2148 12.2894C12.5862 12.3231 12.9745 12.34 13.3684 12.34Z" />
                                            <path
                                                d="M19.5347 3.02734C19.1464 3.22992 18.7357 3.39311 18.3136 3.51691C17.0475 3.89955 15.3875 4.10776 13.6544 4.10776C11.9943 4.10776 10.4131 3.91643 9.19203 3.56192C8.76999 3.44938 8.36484 3.29182 7.97656 3.09487L8.01595 4.68172C8.65182 4.91806 9.24267 5.25569 9.77725 5.67773C9.79413 5.68336 9.80539 5.68336 9.82227 5.68898C10.9027 5.96471 12.2644 6.11102 13.6544 6.11102C15.2637 6.11102 16.8449 5.90844 17.9873 5.56519C19.2083 5.19379 19.4784 4.80552 19.4784 4.73237C19.4784 4.65359 19.4728 4.58044 19.5347 4.50166V3.02734Z" />
                                            <path
                                                d="M17.9793 0.791925C16.837 0.443043 15.2558 0.246094 13.6464 0.246094C12.0258 0.246094 10.5065 0.443043 9.36977 0.791925C8.18244 1.15206 7.9236 1.50657 7.91797 1.61911C7.91797 1.61911 7.9236 1.61911 7.9236 1.63037C7.95173 1.75416 8.28936 2.13681 9.4992 2.48569C10.619 2.81207 12.0933 2.99213 13.6464 2.99213C15.2558 2.99213 16.8314 2.79518 17.9737 2.45193C19.1948 2.08617 19.4705 1.69789 19.4705 1.61911C19.4705 1.54596 19.2004 1.16332 17.9793 0.791925Z" />
                                            <path
                                                d="M25.992 16.3639V14.6758C25.615 14.8671 25.2211 15.0303 24.8103 15.1541C23.5498 15.5367 21.9011 15.7449 20.1623 15.7449C18.6205 15.7449 17.118 15.5705 15.9363 15.261C15.4524 15.1372 14.9853 14.9684 14.5352 14.7489L14.5633 16.3808C14.5633 16.3864 14.5633 16.3864 14.5633 16.3921C14.5633 16.4765 14.8109 16.8535 15.9982 17.2193C17.1236 17.5625 18.643 17.7538 20.2692 17.7538C21.8898 17.7538 23.4148 17.5569 24.5515 17.2024C25.7388 16.831 25.9977 16.4427 25.9977 16.3639H25.992Z" />
                                            <path
                                                d="M19.5193 6.16406C19.131 6.36101 18.7259 6.5242 18.3038 6.648C17.0377 7.03065 15.3777 7.23885 13.6389 7.23885C12.7836 7.23885 11.9339 7.18258 11.0898 7.07566C11.5456 7.70591 11.8833 8.41493 12.0971 9.16897C12.581 9.20274 13.1044 9.22524 13.6446 9.22524C13.6614 9.22524 13.684 9.22524 13.7008 9.22524C14.6237 8.21235 17.1053 7.70591 19.5193 7.63838V6.16406Z" />
                                            <path
                                                d="M24.7708 12.0408C23.5159 12.4122 21.8841 12.6148 20.1734 12.6148C18.5134 12.6148 16.9322 12.4235 15.7111 12.0689C15.2891 11.9508 14.8783 11.7932 14.4844 11.5963L14.5069 12.8905L14.5125 13.3632C14.9514 13.909 17.0504 14.6124 20.1678 14.6124C21.794 14.6124 23.3302 14.4211 24.4838 14.0722C25.6993 13.7008 25.9638 13.3182 25.9638 13.2394C25.9638 13.1775 25.9975 13.11 25.9975 13.0537H26.0031V11.5625C25.6092 11.7594 25.1985 11.9283 24.7708 12.0408Z" />
                                            <path
                                                d="M24.5066 9.31192C23.3587 8.96304 21.7831 8.77734 20.1737 8.77734H20.0781C18.5813 8.77734 17.1576 8.94616 16.0659 9.25565C14.9011 9.58203 14.5016 9.95342 14.4509 10.1054C14.4453 10.1166 14.4453 10.1222 14.4453 10.1279C14.4453 10.1279 14.4453 10.1279 14.4566 10.1504C14.4903 10.2685 14.8223 10.6512 16.0322 11.0057C17.152 11.3264 18.6263 11.5065 20.1794 11.5065C21.7831 11.5065 23.3024 11.3208 24.456 10.9776C25.7052 10.6062 25.9866 10.2179 25.9922 10.1335V10.1279C25.9866 10.0435 25.7052 9.67206 24.5066 9.31192Z" />
                                        </svg>
                                        Auto Recharge
                                    </a>
                                </li>
                            )
                        }
                        <li class="tab">
                            <a class={`px-3 d-flex justify-content-center align-items-center ${props.tab === Constants.TAB_USAGE_HISTORY ? 'active' : ''}`}
                                onClick={e => {
                                    e.preventDefault();
                                    // props.setTab(Constants.TAB_USAGE_HISTORY);
                                    props.setTab(Constants.TAB_USAGE_HISTORY);
                                    if (props.isShowTopPart) { props.handleCollapse() }
                                }}
                                href="#usages__history">
                                <svg class="mr-3" width="27" height="26" viewBox="0 0 27 26" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13.0039" cy="13" r="13" />
                                    <path
                                        d="M13.5095 10.0391C13.1742 10.0391 12.9023 10.3109 12.9023 10.6462V12.6255C12.9023 13.5313 13.3799 14.3701 14.1588 14.8323L15.8753 15.851C16.1468 16.0122 16.4976 15.9225 16.6586 15.6509C16.8195 15.3794 16.7299 15.0288 16.4584 14.8679L15.145 14.0892C14.5075 13.7113 14.1166 13.0251 14.1166 12.284V10.6462C14.1166 10.3109 13.8448 10.0391 13.5095 10.0391Z"
                                        fill="white" />
                                    <path
                                        d="M13.7063 5.99219C9.67895 5.99219 6.42467 9.25456 6.42467 13.2779H3.99609L7.14918 16.431L7.20583 16.5484L10.4723 13.2779H8.04373C8.04373 10.1491 10.5816 7.61125 13.7104 7.61125C16.8392 7.61125 19.377 10.1491 19.377 13.2779C19.377 16.4067 16.8392 18.9446 13.7104 18.9446C12.144 18.9446 10.7313 18.305 9.7073 17.281L8.56181 18.4265C9.8773 19.746 11.6947 20.5636 13.7063 20.5636C17.7337 20.5636 20.9961 17.3012 20.9961 13.2779C20.9961 9.25456 17.7337 5.99219 13.7063 5.99219Z"
                                        fill="white" />
                                </svg>
                                Credit Usage
                            </a>
                        </li>
                        <li class="tab">
                            <a class={`px-3 d-flex justify-content-center align-items-center ${props.tab === Constants.TAB_INVOICE_HISTORY ? 'active' : ''}`}
                                onClick={e => {
                                    e.preventDefault();
                                    props.setTab(Constants.TAB_INVOICE_HISTORY);
                                    if (props.isShowTopPart) { props.handleCollapse() }

                                }}
                                href="#invoice__history">
                                <svg class="mr-3" width="27" height="26" viewBox="0 0 27 26" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13.0039" cy="13" r="13" />
                                    <path
                                        d="M17.1313 7.32602C16.943 7.23928 16.7212 7.27303 16.5672 7.4118L15.7546 8.13129C15.7325 8.15096 15.6992 8.15084 15.6772 8.13105L14.5716 7.14219C14.3716 6.96302 14.0689 6.96302 13.8689 7.14219L12.7643 8.13014C12.7421 8.14999 12.7085 8.14999 12.6863 8.13014L11.5815 7.14219C11.3816 6.96305 11.0788 6.96305 10.8789 7.14219L9.77312 8.13105C9.75099 8.15084 9.71755 8.15093 9.6953 8.13126L8.88199 7.4118C8.66622 7.21992 8.33574 7.23928 8.14386 7.45505C8.05782 7.55181 8.01074 7.67705 8.01173 7.80652V18.5949C8.0098 18.8841 8.24267 19.1201 8.53187 19.122C8.66142 19.1229 8.78667 19.0757 8.88346 18.9896L9.69604 18.2701C9.71814 18.2504 9.75149 18.2506 9.77347 18.2704L10.8791 19.2592C11.079 19.4384 11.3818 19.4384 11.5817 19.2592L12.6865 18.2713C12.7087 18.2514 12.7423 18.2514 12.7645 18.2713L13.8692 19.2592C14.0692 19.4383 14.3719 19.4383 14.5719 19.2592L15.6777 18.2703C15.6998 18.2505 15.7333 18.2504 15.7555 18.2701L16.5688 18.9896C16.7847 19.1814 17.1151 19.1619 17.3069 18.946C17.3928 18.8493 17.4398 18.7242 17.4389 18.5949V7.80652C17.4417 7.59927 17.3207 7.41028 17.1313 7.32602ZM9.95007 11.2099H12.8656C12.995 11.2099 13.0999 11.3147 13.0999 11.4441C13.0999 11.5734 12.995 11.6783 12.8656 11.6783H9.95007C9.82073 11.6783 9.71586 11.5734 9.71586 11.4441C9.71586 11.3147 9.82073 11.2099 9.95007 11.2099ZM15.5006 15.1915H9.95007C9.82073 15.1915 9.71586 15.0867 9.71586 14.9573C9.71586 14.828 9.82073 14.7231 9.95007 14.7231H15.5006C15.6299 14.7231 15.7348 14.828 15.7348 14.9573C15.7348 15.0867 15.6299 15.1915 15.5006 15.1915ZM15.5006 13.4349H9.95007C9.82073 13.4349 9.71586 13.33 9.71586 13.2007C9.71586 13.0714 9.82073 12.9665 9.95007 12.9665H15.5006C15.6299 12.9665 15.7348 13.0714 15.7348 13.2007C15.7348 13.33 15.6299 13.4349 15.5006 13.4349Z"
                                        fill="white" />
                                </svg>
                                Invoice History
                            </a>
                        </li>
                    </ul>

                    <div>
                        {
                            props.tab === Constants.TAB_CARD_INFO && (
                                <CardInfo />
                            )
                        }
                        {
                            props.tab === Constants.TAB_INSTANT_POPUP && (
                                <InstantTopup setTab={props.setTab} />
                            )
                        }
                        {
                            (props.tab === Constants.TAB_AUTO_RECHARGE || props.tab === Constants.TAB_AUTO_RECHARGE_WITH_ACTIVATION) && (
                                <AutoRecharge tab={props.tab} />
                            )
                        }
                        {
                            props.tab === Constants.TAB_USAGE_HISTORY && (
                                <UsageHistory isShowTopPart={props.isShowTopPart} />
                            )
                        }
                        {
                            props.tab === Constants.TAB_INVOICE_HISTORY && (
                                <InvoiceHistory />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BottomPart;