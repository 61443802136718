import { all } from "redux-saga/effects";
import billingMiddleware from "./billingMiddleware";

export default function* rootMiddleware() {
    yield all(
        [
            billingMiddleware(),
        ]
    )
}
