import { Fragment, useEffect, useState } from "react"
import { Button, TextField, makeStyles } from "@material-ui/core";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { activateCard, deleteCard, existingCardList } from "../../../api/billingApi";
import Loader from "../../Common/Loader";
import CustomConfirmAlert from "../../Common/CustomConfirmAlert";

const useStyles = makeStyles((theme) => ({
    inputText: {
      borderBottom: 'none !important',
  
      '& .MuiInputBase-input': {
          padding: '18px 15px 10px 14px !important',
          boxShadow: 'none !important',
          borderBottom: 'none !important',
  
      }
    }
  }));

const ExitingCards = (props) => {
    const [componentLoading, setComponentLoading] = useState(true);
    const [data, setData] = useState([]);

    const classes = useStyles();

    
    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        existingCardList({})
        .then(response => {
            setData(response.data.data);
        }).finally(() => {
            setComponentLoading(false);
        });
    }

    const makeDefault = (_id) => {
        // confirmAlert({
        //     title: 'Are you sure?',
        //     message: 'Do you want to make this card default?',
        //     buttons: [
        //         {
        //             label: 'Yes',
        //             onClick: () => {
        //                 activateCard({
        //                     "form_data": {
        //                         card_id: _id
        //                     }
        //                 })
        //                 .then(response => {
        //                     props.callback();
        //                 });
        //             }
        //         },
        //         {
        //             label: 'No',
        //             onClick: () => {
        //                 return;
        //             }
        //         }
        //     ]
        // })

        CustomConfirmAlert({
            handleConfirm : () => {
                activateCard({
                    "form_data": {
                        card_id: _id
                    }
                })
                    .then(response => {
                        props.callback();
                    });
            },
            handleOnClose: () => {},
            title: 'Are you sure?',
            description: 'Do you want to make this card default?',
            icon: 'question',
            closeButtonText: 'No',
            confirmButtonText: 'Yes',
            minHeight: '200px'
        });
    }

    const deleteData = (_id) => {
        // confirmAlert({
        //     title: 'Are you sure?',
        //     message: 'Do you want to delete this card?',
        //     buttons: [
        //         {
        //             label: 'Yes',
        //             onClick: () => {
        //                 deleteCard({
        //                     "form_data": {
        //                         card_id: _id
        //                     }
        //                 })
        //                 .then(response => {
        //                     props.callback();
        //                 });
        //             }
        //         },
        //         {
        //             label: 'No',
        //             onClick: () => {
        //                 return;
        //             }
        //         }
        //     ]
        // })

        CustomConfirmAlert({
            handleConfirm : () => {
                deleteCard({
                    "form_data": {
                        card_id: _id
                    }
                })
                    .then(response => {
                        props.callback();
                    });
            },
            handleOnClose: () => {},
            title: 'Are you sure?',
            description: 'Do you want to delete this card?',
            icon: 'question',
            closeButtonText: 'No',
            confirmButtonText: 'Yes',
            minHeight: '200px'
        });
    }

    return (
        <Fragment>
            {
                componentLoading ? (
                    <div style={{paddingTop: 20, paddingLeft: 100}}>
                        <Loader/>
                    </div>
                ) : (
                   <table class="table">
                        <tbody>
                        {
                            data.map((item, index) => (
                                <tr style={{borderBottom: 'none'}}>
                                    <th>
                                        <TextField disabled className={classes.inputText} label={`Card Number **** **** **** ${item.card_number}`} variant="outlined" />
                                    </th>
                                    {/* <th><label>**** **** ****{item.card_number}</label></th> */}
                                    {
                                        (item.status !== 1) && (
                                            <td>
                                                <Button variant="contained" className="mr-2 accent--bg--color" onClick={() => makeDefault(item.id)}>
                                                    Make Default
                                                </Button>
                                                <Button variant="contained" color="secondary" onClick={() => deleteData(item.id)}>
                                                    Delete
                                                </Button>
                                            </td>
                                        )
                                    }
                                </tr>
                            ))
                        }
                        
                        </tbody>
                    </table>
                )
            }
        </Fragment>
    )
}

export default ExitingCards;