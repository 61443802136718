const CoreConstants = {
    TRUE: 1,
    FALSE: 0,

    SMS: 1,
    MMS: 2,
    VOICE: 3,
    EMAIL: 4,
    VIRTUAL_NUMBER: 17,
    PACKAGE_BUY: 6,
    PAY_AS_YOU_GO_BUY: 7,
    PACKAGE_RENEW: 8,
    CREDIT_ADJUSTMENT: 9,
    AUTO_RECHARGE: 10,
    ADDITIONAL_USER_BUY: 11,
    CALL: 12,
    CALLBRIDGE: 13,
    CALL_RECORD: 20,

    POST_CARD: 21,
    GREETINGS_CARD: 22,
    GIFT: 23,
    LETTER: 24,

    POWER_DIALER_CALL: 25,
    POWER_DIALER_CALL_RECORD: 26,

    CARRIER_LOOKUP: 64,
    CALLER_NAME_LOOKUP: 63, 
    EMAIL_LOOKUP: 65, 

    REGULAR_PACKAGE: 1,
    PAY_AS_YOU_GO_PACKAGE: 2,
    INSTANT_TOPUP: 112,
    ADMIN_INSTANT_TOPUP: 113,

    /* for central billing */
    A2P_USER_REGISTRATION : 51,
    A2P_STANDARD_REGISTRATION : 52,
    A2P_CAMPAIGN_REGISTRATION : 53,
    A2P_CAMPAIGN_RENEW : 54,
    INCOMING_SMS : 56,
    INCOMING_MMS : 57,
    INCOMING_CALL : 58,
    DNC:66,
    SKIP_TRACING:67,
    SMS_CARRIER_FEES:55,
    MMS_CARRIER_FEES:59,
    PER_MINUTE_CHARGE_OUTGOING_CALL:60,
    CALL_CLIENT_CHARGE:61,
    CALL_GLOBAL_CONFERENCE_CHARGE:62,
    LOOKUP_CALLER_NAME:63,
    LOOKUP_CARRIER:64,
    LOOKUP_VERIFY_EMAIL:65

}

export const LAYOUT_SETTINGS = {
    SHORT_MENU         : 19,
    TOPBAR_BACKGROUND  : 20,
    TOPBAR_TEXT_COLOR  : 21,
    SIDEBAR_BACKGROUND : 22,
    SIDEBAR_TEXT_COLOR : 23,
    ACCENT_COLOR : 26,
}

export const AGENCY_BUSINESS_TYPE = {
    INSURANCE: "INSURANCE",
    REGULAR: "REGULAR"
  };

export const PERMISSION_MODULES = {
    MANAGE_BILLING: 0,
    MANAGE_USERS: 1,
    MANAGE_GLOBAL_ITEMS: 2,
    EXPORT_CONTACTS: 3,
    EDIT_LEAD_OWNER: 4,
    MANAGE_OTHER_USER_GOALS: 5,
    MANAGE_OTHER_USER_REPORTS: 6,
    MANAGE_OTHER_USER_CALENDAR: 7,
    MANAGE_OTHER_USER_SCHEDULER: 8,
  };
  
export const PERMISSION_LEVELS = {
    READ: 2,
    WRITE: 4,
    DELETE: 8,
  };

export default CoreConstants;