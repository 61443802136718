import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';

// import components

import Billing from "../components/Billing";
import Transaction from "../components/Transaction";
import HealthCheck from '../components/HealthCheck';
import CancellationRequest from "../components/CancellationRequest";
import CancellationRequestConfirm from "../components/CancellationRequest/confirm";

const Routes = () => {

        return (
            <Router>
                <Switch>
                    <Route exact path="/"><Redirect to="/billing" /></Route>

                    <Route exact path="/billing" component={Billing}/>

                    <Route path="/transaction-details/:id" component={Transaction}/>

                    <Route path={"/billing/health-check"} component={HealthCheck}/>

                    <Route exact path="/cancellation-request" component={CancellationRequest}/>
                    <Route exact path="/cancellation-request/confirm" component={CancellationRequestConfirm}/>
                </Switch>
            </Router>
        );
}

export default Routes;