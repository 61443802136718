import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const fetchbillingList = async (payload) => {
   return sendRequest("POST", Api.billings, payload);
}

export const getTransactionDetails = async (payload) => {
    return sendRequest("post", Api.getTransactionDetails, payload);
}

export const fetchDetails = async (payload) => {
    return sendRequest("POST", Api.details, payload);
}

export const existingCardList = async (payload) => {
    return sendRequest("post", Api.existingCardList, payload.form_data);
}

export const activateCard = async (payload) => {
    return sendRequest("post", Api.activateCard, payload.form_data);
}

export const deleteCard = async (payload) => {
    return sendRequest("post", Api.deleteCard, payload.form_data);
}

export const saveCard = async (payload) => {
    return sendRequest("post", Api.saveCard, payload.form_data);
}

export const getCardInfoSettings = async (payload) => {
    return sendRequest("post", Api.getCardInfoSettings, payload.form_data);
}

export const getInstantTopupSettings = async (payload) => {
    return sendRequest("post", Api.getInstantTopupSettings, payload.form_data);
}

export const getInstantTopupSettingsData = async (payload) => {
    return sendRequest("post", Api.getInstantTopupSettingsAll, payload.form_data);
}

export const rechargeInstantTopup = async (payload) => {
    return sendRequest("post", Api.rechargeInstantTopup, payload.form_data);
}

export const getRechargeSettings = async (payload) => {
    return sendRequest("post", Api.getRechargeSettings, payload.form_data);
}

export const updateRechargeSettings = async (payload) => {
    return sendRequest("post", Api.updateRechargeSettings, payload.form_data);
}

export const removeRechargeSettings = async (payload) => {
    return sendRequest("post", Api.removeRechargeSettings, payload.form_data);
}

export const currentPackageHistoryList = async (payload) => {
    return sendRequest("post", Api.currentPackageHistoryList, payload.form_data);
}

export const transactionDetailsWithInfo = async (payload) => {
    return sendRequest("post", Api.transactionDetailsWithInfo, payload.form_data);
}

export const invoiceList = async (payload) => {
    return sendRequest("post", Api.invoiceList, payload.form_data);
}

export const getInvoiceDetails = async (payload) => {
    return sendRequest("post", Api.invoiceDetails, payload.form_data);
}

export const payNowForTrialPack = async (payload) => {
    return sendRequest("post", Api.payNowForTrialPack, payload.form_data);
}

export const archievePackgeListApi = async (payload) => {
    return sendRequest("post", Api.archievePackageList, payload.form_data);
}

export const accountCancellation = async (payload) => {
    return sendRequest("post", Api.accountCancellation, payload);
}