import React, { useEffect, useMemo, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import useResponsiveFontSize from "../../../hooks/useResponsiveFontSize";
import Utils from "../../../helpers/Utils";
import { saveCard } from "../../../api/billingApi";


const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#9e2146"
                },
            },
            hidePostalCode: true
        }),
        [fontSize]
    );

    return options;
};

const ChangeCardStripeForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    useEffect(() => {
        if (props.user) {
            setEmail(props.user.email.toLowerCase())
        }
    }, [props.user])

    const handleSubmit = async event => {
        event.preventDefault();
        
        

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const token = await stripe.createToken(elements.getElement(CardElement));

        if (email !== '' && !(email.substr(0, email.indexOf('@')).match(/(.*[a-z]){1}/))) {
            Utils.showNotification('Invalid Email', 'error');
            return false;
        }

        if (typeof token.error === 'undefined' && token.token) {
            setLoading(true);

            saveCard({
                "form_data": {
                    stripeToken: token.token.id,
                    card: token.token.card.last4,
                    email: email
                }
            }).then((response) => {
                if(response.data.status !== undefined && response.data.status === 'error'){
                    if(response.data.data !== undefined){
                        Utils.showNotification(response.data.data, 'error');
                        props.callback();
                    }
                }else{
                    props.callback();
                }

            });
        }     
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={{marginBottom: '10px'}}>
                <TextField
                    id="outlined-email"
                    label="Email"
                    multiline
                    maxRows={4}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    disabled
                />
            </div>
            {/* <label>
                Email
                <p>
                    <input type='email' readOnly value={email} onChange={(e) => setEmail(e.target.value)}/>
                </p>
            </label> */}
            
            <label>
                Card details
                <CardElement
                    options={options}
                    onReady={() => {
                        // console.log("CardElement [ready]");
                    }}
                    onChange={event => {
                        // console.log("CardElement [change]", event);
                    }}
                    onBlur={() => {
                        // console.log("CardElement [blur]");
                    }}
                    onFocus={() => {
                        // console.log("CardElement [focus]");
                    }}
                />
            </label>
            <div className="mt-3">
                <Button className={"accent--bg--color text-white"} disabled={loading || !stripe} onClick={handleSubmit}>
                    {!loading ? 'Save Card' : 'Saving...'}
                </Button>
            </div>
        </form>
    );
};

const mapStateToProps = state => {
    return {
        user: state.billingReducer.user
    }
}
const mapDispatchToProps = dispatch => {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeCardStripeForm);