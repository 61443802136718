import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import App from './App';
import store from "./store/Store"
import * as serviceWorker from './serviceWorker';
// import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';


window.renderBillingModule = (containerId, history) => {
    ReactDOM.render(
        // <React.StrictMode>
        <React.Fragment>
            <Provider store={store}>
                {/* <ClearCacheProvider duration={5000}> */}
                    <App history={history} />
                {/* </ClearCacheProvider> */}
            </Provider>
        </React.Fragment>,
        // </React.StrictMode>,
        document.getElementById(containerId)
    );
    serviceWorker.unregister();
};

window.unmountBillingModule = (containerId) => {
    if (document.getElementById(containerId)) {
        ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
    }
};

if (process.env.REACT_APP_PRODUCTION_MODE !== true && document.getElementById('BillingModule-root')) {
    ReactDOM.render(
        // <React.StrictMode>
        <React.Fragment>
            <Provider store={store}>
                <App />
            </Provider>
        </React.Fragment>,
        // </React.StrictMode>,
        document.getElementById('BillingModule-root'));
    serviceWorker.unregister();
}
