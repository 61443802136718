import React, {createContext} from "react";
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import {BrowserRouter as Router} from 'react-router-dom';
import { useDispatch } from "react-redux";
import { fetchDetails } from "./actions/billingAction";
import {createGenerateClassName, StylesProvider} from "@material-ui/core";

const defaultHistory = createBrowserHistory();
export const HistoryProvider = createContext({});

const generateClassName = createGenerateClassName({
    productionPrefix: 'billing',
});

function App({ history = defaultHistory }) {
    const dispatch = useDispatch();

    dispatch(fetchDetails());

    return (
        <HistoryProvider.Provider value={history}>
            <Router history={history}>
                <React.Fragment>
                    <StylesProvider generateClassName={generateClassName}>
                        <Routes />
                    </StylesProvider>
                </React.Fragment>
            </Router>
        </HistoryProvider.Provider>
    );
}

export default App;
