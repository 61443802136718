import { Button } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { getInstantTopupSettings, getRechargeSettings, updateRechargeSettings } from "../../api/billingApi";
import { getAccountData } from "../../helpers/Utils";
import { Alert } from "@material-ui/lab";
import { LAYOUT_SETTINGS } from "../../constants/CoreConstants";
import CustomSwitch from "../Common/Switch";
import DynamicSkeleton from "./BottomPart/DynamicSkeleton";


const options = [
    // { value: 10, label: '$10' },
    { value: 25, label: '$25' },
    { value: 50, label: '$50' },
    { value: 75, label: '$75' },
    { value: 150, label: '$150' },
    { value: 200, label: '$200' },
    { value: 250, label: '$250' },
    { value: 500, label: '$500' },
    { value: 1000, label: '$1000' },
    { value: 1500, label: '$1500' },
    { value: 2000, label: '$2000' },
    { value: 2500, label: '$2500' },
    { value: 3000, label: '$3000' },
    { value: 3500, label: '$3500' },
    { value: 4000, label: '$4000' },
    { value: 4500, label: '$4500' },
    { value: 5000, label: '$5000' },
    { value: 5500, label: '$5500' },
    { value: 6000, label: '$6000' },
    { value: 6500, label: '$6500' },
    { value: 7000, label: '$7000' },
    { value: 7500, label: '$7500' },
    { value: 8000, label: '$8000' },
    { value: 8500, label: '$8500' },
    { value: 9000, label: '$9000' },
    { value: 9500, label: '$9500' },
    { value: 10000, label: '$10000' }
]

const AutoRecharge = (props) => {
    const [componentLoading, setComponentLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    

    const [refillAmount, setRefillAmount] = useState(options[0].value);
    const [creditReach, setCreditReach] = useState(options[0].value);
    const [refillStatus, setRefillStatus] = useState(false);
    const [customer, setCustomer] = useState(null);


    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        getInstantTopupSettings({})
        .then(response => {
            setCustomer(response.data.data);
            if(response.data.data !== false){
                getRechargeSettings({})
                .then(response => {
                    const result = response.data.data
                    if (typeof result.refill_amount !== 'undefined') {
                        if(result.refill_amount === ""){
                            setRefillAmount(options[0].value);
                        }
                        else{
                            setRefillAmount(result.refill_amount);
                            setRefillStatus(true);
                        }
                    }
                    if (typeof result.credit_reach !== 'undefined') {
                        if(result.credit_reach === ""){
                            setCreditReach(options[0].value);
                        }
                        else{
                            setCreditReach(result.credit_reach);
                        }
                    }
                }).finally(() => {
                    setComponentLoading(false);
                });
            }
        }).finally(() => {
            setComponentLoading(false);
        });
    }

    const submitData = () => {
        setLoading(true);
        setRefillStatus(true);
        updateRechargeSettings({
            "form_data": {
                refill_amount: (refillAmount === null ? '' : parseInt(refillAmount)),
                credit_reach: (creditReach === null ? '' : parseInt(creditReach)),
            }
        }).then((response) => {
            // setRefillStatus(true);
            if(response.data.status !== 'success'){
                setRefillStatus(false);
            }
            else
            window.showNotification("SUCCESS", response.data.message)
        }).finally(() => {
            setLoading(false);
        }).catch(error => {
            setRefillStatus(false);
        })
    }

    const removeLoading = () => {
        setRefillStatus(false);
        updateRechargeSettings({
            "form_data": {

            }
        }).then((response) => {
            // setRefillStatus(false);
            if(response.data.status !== 'success'){
                setRefillStatus(true);
            }else
            window.showNotification("SUCCESS", response.data.message)
        }).catch(error => {
            setRefillStatus(true);
        })
    }

    let brandColor = '#27AE60';
    let fetchBrandColor = getAccountData('layoutSettings');
    if(fetchBrandColor !== undefined && fetchBrandColor[LAYOUT_SETTINGS.ACCENT_COLOR]) {
        brandColor = fetchBrandColor[LAYOUT_SETTINGS.ACCENT_COLOR]
    }

    return (
        <Fragment>
            {
                (componentLoading || !props.user) ? (
                    <DynamicSkeleton count={3}/>
                ) : (
                    <Fragment>
                        {
                            (props.isTrial) ? (
                                <div style={{paddingTop: 50}}>
                                    <Alert severity="error">This feature is not available for you currently. You are in trial mode</Alert>
                                </div>
                            ) : (
                                (customer && typeof customer.id !== 'undefined') ? (
                                    <div id="recharge_setting" class="col s12 mt-5">
                                        <div className="row">
                                            <div class="col s12">
                                                Auto Recharge is - {" "}
                                                <CustomSwitch
                                                    status={refillStatus}
                                                    brandColor={brandColor}
                                                    onChange={refillStatus ? () => removeLoading() : () => submitData()}
                                                />
                                                {/* <div className="running_switch" onClick={refillStatus ? () => removeLoading() : () => submitData() }>
                                        <div id="MainContainer">
                                            <div className="demo">
                                                <div className={`Switch ${refillStatus ? 'On': 'Off'} d-flex align-items-center`}>
                                                    <div className="Toggle"></div>
                                                    <span className="On">ON</span> <span className="Off">OFF</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                                {/* <strong color={"text-red"}>{refillStatus ? 'ON' : 'OFF'} </strong> */}
                                            </div>
                                            {
                                                (refillAmount && creditReach) && (
                                                    <div class="col s12 mt-2">
                                                        <Alert severity="info">Your account will refill
                                                            ${refillAmount} when your dollar reach below
                                                            ${creditReach}.</Alert>
                                                    </div>
                                                )
                                            }
                                            <div class="col s6">
                                                
                                                <p className="mt-4 mb-2">
                                                    Recharge my account automatically to this amount                                               
                                                </p>

                                                <div>
                                                    <Select
                                                        // styles={{width: '100px'}}
                                                        options={options}
                                                        isClearable={false}
                                                        isDisabled={!refillStatus}
                                                        value={{
                                                            value: (refillAmount ? refillAmount : options[0].value),
                                                            label: `$${(refillAmount ? refillAmount : options[0].value)}`,
                                                        }}
                                                        styles={{
                                                            menu: provided => ({...provided, zIndex: 9999999}),
                                                            valueContainer: provided => ({
                                                                ...provided,
                                                                width: '50px',
                                                                '& > div:last-child': {
                                                                    height: '45px'               
                                                                }
                                                            })
                                                        }}
                                                        onChange={(_selectedOption) => {
                                                            setRefillAmount(_selectedOption.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col s6">
                                                <p className="mt-4 mb-2">
                                                    When my dollar balance is equal or below
                                                </p>

                                                <div>
                                                    <Select
                                                        // styles={{width: '100px'}}
                                                        options={options}
                                                        isClearable={false}
                                                        isDisabled={!refillStatus}
                                                        value={{
                                                            value: (creditReach ? creditReach : options[0].value),
                                                            label: `$${(creditReach ? creditReach : options[0].value)}`,
                                                        }}
                                                        styles={{
                                                            menu: provided => ({...provided, zIndex: 9999999}),
                                                            valueContainer: provided => ({
                                                                ...provided,
                                                                width: '50px',
                                                                '& > div:last-child': {
                                                                    height: '45px'               
                                                                }
                                                            })
                                                        }}
                                                        onChange={(_selectedOption) => {
                                                            setCreditReach(_selectedOption.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div class={`col s12 mt-2 ${refillStatus ? '' : 'd-none'}`}>
                                                <Button className={"mr-2 accent--bg--color accent--bg--text--color"}
                                                        variant="contained" disabled={loading} onClick={submitData}>
                                                    {!loading ? 'Save Auto Recharge Setting' : 'Saving...'}
                                                </Button>

                                                {/* {refillStatus &&
                                        <Button variant="contained" color="secondary" disabled={removeSettingLoading}
                                                onClick={removeLoading}>
                                            {!removeSettingLoading ? 'Remove Auto Recharge Setting' : 'Saving...'}
                                        </Button>
                                    } */}

                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{paddingTop: 50}}>
                                        <Alert severity="error">This feature is not available for you currently. Please
                                            add card information.</Alert>
                                    </div>
                                )
                            )

                        }

                    </Fragment>
                )
            }
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.billingReducer.user,
        isTrial: state.billingReducer.isTrial
    }
}
const mapDispatchToProps = dispatch => {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoRecharge);